$isProd: true;
@import "./colors";
@import "./mixins";

.line-through {
  text-decoration-line: line-through!important;
}

.block {
  display: block;
}

.inlineBlock {
  display: inline-block;
}

.flex {
  &--justifyContentCenter,
  &--justifyCenter {
    justify-content: center;
  }

  &--spaceBetween {
    justify-content: space-between;
  }

  &--nowrap {
    flex-wrap: nowrap;
  }

  &--width-auto {
    flex: 0;
  }
}

.flexFirstItem {
  order: -1;
}

.flexWrap {
  flex-wrap: wrap !important;
}

.alignCenter {
  text-align: center;
}

.alignRight {
  text-align: right;
}

.flex-0 {
  flex: 0;
}

.flex-child {
  flex: 1;
}

.flex-column {
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.justify-center {
  justify-content: center;
}

.grayBorder {
  border: 1px solid $table-gray-border;
}

.bln {
  border-left: 0 !important;
}

.brn {
  border-right: 0!important;
}

.black {
  color: $black;
  fill: $black;
}

.grayText {
  color: $gray-text;
}

.lightGray3 {
  color: $lightgray-3;
}

.primary {
  color: $primary;
}

.lightPrimary {
  color: $light_primary;
}

.success {
  color: $success;
}

.warning {
  color: $warning;
}

.text-danger {
  color: $danger!important;
}

.blue {
  color: $blue;
}

.btn-corporate {
  color: $white;
  background-color: $light_primary;
}
.btn-corporate:hover,
.btn-corporate:focus,
.btn-corporate:active,
.btn-corporate.active,
.open > .dropdown-toggle.btn-corporate {
  color: $white;
  background-color: $primary_hover;
}

.p0 {
  padding: 0!important;
}

.p2 {
  padding: 2px;
}

.pt35 {
  padding-top: 35px;
}

.pb35 {
  padding-bottom: 35px;
}

.auto-event {
  pointer-events: auto!important;
}

.nowrap {
  white-space: nowrap!important;
}

.disabled {
  cursor: not-allowed!important;
  pointer-events: initial!important;
  opacity: 0.65;
}

.ellipsisText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.veryLighter {
  @include light-mixin(41%, 30%);
}

.upperCaseFirstLetter {
  display: inline-block;

  &:first-letter {
    text-transform: uppercase;
  }
}

.danger {
  background-color: $danger;

  &:hover {
    background-color: $danger-hover;
  }
}

.system {
  background-color: $system;

  &:hover {
    background-color: $system-hover;
  }
}

.corporate {
  background-color: $primary;

  &:hover {
    background-color: $primary_hover;
  }
}

.br-n {
  border: none !important;
}

.posr {
  position: relative !important;
}

.posa {
  position: absolute !important;
}

.posaCenter {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt40 {
  margin-top: 40px;
}

.mt15 {
  margin-top: 15px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.mr10 {
  margin-right: 10px;
}

.mr15 {
  margin-right: 15px;
}

.mr20 {
  margin-right: 20px;
}

.mr5 {
  margin-right: 5px;
}

.p15 {
  padding: 15px;
}

.pl10 {
  padding-left: 10px;
}

.pr5 {
  padding-right: 5px;
}

.pl20 {
  padding-left: 20px;
}

.pr20 {
  padding-right: 20px;
}

.pl5 {
  padding-left: 5px;
}

.pt5 {
  padding-top: 5px;
}

.pb5 {
  padding-bottom: 5px;
}

.pl140 {
  padding-left: 140px;
}

.pt30 {
  padding-top: 30px;
}

.pb30 {
  padding-bottom: 30px;
}

.pr140 {
  padding-right: 140px;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mt-auto {
  margin-top: auto;
}

.lh20 {
  line-height: 20px;
}

.fullWidth {
  width: 100%;
}

.autoWidth {
  width: auto !important;
}

.fw300 {
  font-weight: 300;
}

.fw400 {
  font-weight: 400;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 600;
}

.fs3 {
  font-size: 3px;
}

.fs9 {
  font-size: 9px;
}

.fs10 {
  font-size: 10px;
}

.fs11 {
  font-size: 11px;
}

.fs12 {
  font-size: 12px;
}

.fs13 {
  font-size: 13px;
}

.fs14 {
  font-size: 14px;
}

.fs15 {
  font-size: 15px;
}

.fs16 {
  font-size: 16px;
}

.fs18 {
  font-size: 18px;
}

.fs20 {
  font-size: 20px;
}

.fs18 {
  font-size: 18px;
}

.op-0 { opacity: 0; }
.op-1 { opacity: .1; }
.op-2 { opacity: .2; }
.op-3 { opacity: .3; }
.op-4 { opacity: .4; }
.op-5 { opacity: .5; }
.op-6 { opacity: .6; }
.op-7 { opacity: .7; }
.op-8 { opacity: .8; }
.op-9 { opacity: .9; }
.op-10 { opacity: 1; }

.cursor {
  cursor: pointer;
}

.panel {
  position: relative;
  margin-bottom: 20px;
  background-color: $white;
  border-radius: 2px;
}

.h30 {
  height: 30px;
}

.card-shadow {
  box-shadow: 0 0 20px 0 rgba(0,0,0,.05);
}

.w-50 {
  width: 50%;
}

.w20 {
  width: 20px;
}

.text-lightgray-3 {
  color: $lightgray-3;
}

.posf {
  position: fixed !important;
}

.circle {
  border-radius: 50%;
}

.hiddenElement {
  position: absolute;
  visibility: hidden;
}

.visibilityHidden {
  visibility: hidden;
}

.emptyCenteredLayout {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba($white, .5);
}

.noMinHeight {
  min-height: auto !important;
}

.textCapitalize {
  text-transform: capitalize;
}

.verticalAlignMiddle {
  vertical-align: middle;
}
