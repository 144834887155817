$isProd: true;
*, :after, :before {
  border: 0 solid #ddd;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  background: none;
}

p {
  margin: 0;
}

.asodesk-desktop {
  min-width: 1260px;

  @media print {
    min-width: auto;
  }
}

.asodesk-mobile {
  min-width: auto;

  // this lines need for mobile Safari full height size without using 100vh, which add additional height when use sticky bottom
  min-height: 100%;
  height: 1px;
  // for ios safari min-height above for fallback:
  min-height: -webkit-fill-available;


  #asodesk-react-root {
    min-height: 100%;
    height: 1px;
    // for ios safari min-height above for fallback:
    min-height: -webkit-fill-available;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Highcharts
   ========================================================================== */
.highcharts-tooltip-container {
  z-index: 10000 !important;
}

#hs-feedback-ui {
  z-index: 1000000001;
  pointer-events: auto !important;

  & * {
    pointer-events: auto !important;
  }
}

#hubspot-messages-iframe-container {
  min-height: 92px !important;
  min-width: 92px !important;
  margin: -8px;
}
