$isProd: true;
.xxl {
  width: 54px;
}

.xl {
  width: 46px;
}

.lg {
  width: 35px;
}

.sm {
  width: 20px;
}

.xs {
  width: 10px;
}

.white {
  filter: brightness(0) invert(1);
}
