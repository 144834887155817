$isProd: true;
@import '../../../../styles/mixins';

.trial {
  @apply text-success relative;
}

.stress {
  width: 148px;
  height: 8px;
  display: block;
  position: absolute;
  right: -4px;
  bottom: -1px;
  @include bgImage('stress.svg');
  background-size: cover;
}

.width {
  max-width: 1366px;
  @apply mx-auto;
}

.blocks {
  @apply mt-32;
}

.buttons {
  @apply grid gap-16;
}

.container {
  max-height: calc(100vh - 333px) !important;
}
