$isProd: true;
.badge {
  @apply flex items-center uppercase text-12 text-white font-bold shadow-8-1 absolute tracking-7 rotate-45 h-36 text-center justify-center;
  @apply bg-success #{!important};

  width: 237px;
  right: -72px;
  top: 29px;
}

.bgPlanOffer {
  background: linear-gradient(148.37deg, #F39280 -2.61%, #EC674D 102.95%);
}
