$isProd: true;
.ballSpinFadeLoader {
    position: relative;
    top: -10px;
    left: -10px;

    &--md {
        transform: scale(0.4);
        top: -8px;
    }

    &--lg {
        transform: scale(1);
    }

    &--primary {
        & > div {
            @apply bg-primary #{!important};
        }
    }

    &--default {
        & > div {
            @apply bg-white #{!important};
        }
    }
}

.ballSpinFadeLoader > div {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    margin: 2px;
    animation-fill-mode: both;
    position: absolute;
}

@-webkit-keyframes ballSpinFadeLoader {
    50% {
        opacity: .3;
        transform: scale(.4)
    }

    100% {
        opacity: 1;
        transform: scale(1)
    }
}

.ballSpinFadeLoader > div:nth-child(1) {
    top: 25px;
    left: 0;
    animation: ballSpinFadeLoader 1s -.96s infinite linear
}

.ballSpinFadeLoader > div:nth-child(2) {
    top: 17.05px;
    left: 17.05px;
    animation: ballSpinFadeLoader 1s -.84s infinite linear
}

.ballSpinFadeLoader > div:nth-child(3) {
    top: 0;
    left: 25px;
    animation: ballSpinFadeLoader 1s -.72s infinite linear
}

.ballSpinFadeLoader > div:nth-child(4) {
    top: -17.05px;
    left: 17.05px;
    animation: ballSpinFadeLoader 1s -.6s infinite linear
}

.ballSpinFadeLoader > div:nth-child(5) {
    top: -25px;
    left: 0;
    animation: ballSpinFadeLoader 1s -.48s infinite linear
}

.ballSpinFadeLoader > div:nth-child(6) {
    top: -17.05px;
    left: -17.05px;
    animation: ballSpinFadeLoader 1s -.36s infinite linear
}

.ballSpinFadeLoader > div:nth-child(7) {
    top: 0;
    left: -25px;
    animation: ballSpinFadeLoader 1s -.24s infinite linear
}

.ballSpinFadeLoader > div:nth-child(8) {
    top: 17.05px;
    left: -17.05px;
    animation: ballSpinFadeLoader 1s -.12s infinite linear
}

.ballSpinFadeLoader > div {
    animation-fill-mode: both;
    position: absolute
}
