$isProd: true;
.switch {
  width: 40px;
  height: 24px;

  input[type=checkbox] {
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

.pointer {
  height: 20px;
  width: 20px;
}

.smallTrigger {
  transform: scale(0.6666);
  @apply -mx-6;
}
