$isProd: true;
@import "../../../styles/colors";

.buttonElement {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  border: 1px solid;
  white-space: nowrap;

  &__loader {
    .inlineLoader {
      width: 20px;
    }
  }

  &__label {
    margin-left: 2px;
  }

  &__icon {
    &--indent {
      margin-right: 5px;
    }
  }

  &__imageIcon {
    margin-right: 15px;
  }

  &:hover, &:focus {
    text-decoration: none;
  }

  &--highlight {
    border-color: $gray-border;
    color: $primary;
    background-color: $lightgray;

    &:hover {
      background-color: $mediumgray;
    }
  }

  &--primary {
    @apply bg-rb-accent-blue-default text-rb-bg-white border-rb-accent-blue-default;

    &:hover {
      @apply bg-rb-accent-blue-hover border-rb-accent-blue-hover text-rb-bg-white;
    }
  }

  &--white {
    @apply bg-rb-bg-white text-rb-accent-blue-default border-rb-bg-white;

    &:hover {
      @apply bg-opacity-95;
    }
  }

  &--lightPrimary {
    background-color: $light_primary;
    color: $white;
    border-color: $light_primary;

    &:hover {
      border-color: lighten($light_primary, 5%);
      background-color: lighten($light_primary, 5%);
      color: $white;
    }
  }

  &--lightBlue {
    @apply bg-light-blue-100 bg-opacity-25 text-primary;
    @apply border border-solid border-light-blue-100 border-opacity-25;

    &:hover {
      @apply bg-opacity-30;
    }
  }

  &--default {
    @apply text-gray-500 bg-gray-50 border-gray-370;

    &:hover {
      color: $gray-text;
      background-color: lighten($table-gray-border, 5%);
    }
  }

  &--secondary {
    background-color: $gray-border;
    color: $gray-text;
    border-color: $gray-border;

    &:hover {
      color: $gray-text;
      background-color: darken($gray-border, 5%);
      border-color: darken($gray-border, 5%);
    }
  }

  &--dark {
    background-color: $darkgray-text;
    border-color: $darkgray-text;
    color: $lightgray;

    &:hover {
      color: $lightgray;
      background-color: darken($darkgray-text, 5%);
      border-color: darken($darkgray-text, 5%);
    }
  }

  &--alert {
    background-color: $alert;
    border-color: $alert;
    color: $white;

    &:hover {
      color: $white;
      background-color: lighten($alert, 5%);
      border-color: lighten($alert, 5%);
    }
  }

  &--danger {
    @apply bg-danger border-danger text-white;

    &:hover {
      @apply text-white;
      background-color: lighten($danger, 5%);
      border-color: lighten($danger, 5%);
    }
  }

  &--success {
    background-color: $success;
    color: $white;
    border-color: $success;

    &:hover {
      color: $white;
      background-color: lighten($success, 5%);
      border-color: lighten($success, 5%);
    }
  }

  &--warning {
    background-color: $warning;
    border-color: $warning;
    color: $white;

    &:hover {
      color: $white;
      background-color: lighten($warning, 5%);
      border-color: lighten($warning, 5%);
    }
  }

  &--info {
    @apply bg-info border-info text-white;

    &:hover {
      @apply bg-opacity-95 border-opacity-95 text-white;
    }
  }

  &--offer {
    @apply bg-offer border-offer text-white;

    &:hover {
      @apply bg-opacity-95 border-opacity-95 text-white;
    }
  }

  &--outline {
    background: transparent;

    &:hover {
      background: transparent;
    }

    &.buttonElement--primary {
      color: $primary;

      &:hover {
        color: lighten($primary, 5%);
        border-color: lighten($primary_hover, 5%);
      }
    }

    &.buttonElement--lightPrimary {
      color: $light_primary;

      &:hover {
        color: lighten($light_primary, 5%);
        border-color: lighten($light_primary, 5%);
      }
    }

    &.buttonElement--danger {
      color: $danger;

      &:hover {
        color: lighten($danger, 5%);
        border-color: lighten($danger_hover, 5%);
      }
    }

    &.buttonElement--alert {
      color: $alert;

      &:hover {
        color: lighten($alert, 5%);
        border-color: lighten($alert, 5%);
      }
    }

    &.buttonElement--success {
      color: $success;

      &:hover {
        color: lighten($success, 5%);
        border-color: lighten($success, 5%);
      }
    }

    &.buttonElement--warning {
      color: $warning;

      &:hover {
        color: lighten($warning, 5%);
        border-color: lighten($warning, 5%);
      }
    }

    &.buttonElement--default {
      color: $gray-text;
      border-color: $table-gray-border;

      &:hover {
        color: $gray-text;
        border-color: lighten($table-gray-border, 5%);
      }
    }

    &.buttonElement--lightPrimary {
      color: $light_primary;
      border-color: $light_primary;

      &:hover {
        border-color: lighten($light_primary, 5%);
        color: lighten($light_primary, 5%);
      }
    }

    &.buttonElement--dark {
      color: $gray-text;
      border-color: $gray-text;

      &:hover {
        color: $darkgray-text;
        border-color: $darkgray-text;
      }
    }
  }

  &--xs {
    height: 24px;
    padding: 0 7px;

    &.buttonElement--icon {
      width: 23px;
      padding: 0;
    }
  }

  &--sm {
    height: 23px;
    padding: 0 25px;
    line-height: 1;

    &.buttonElement--icon {
      width: 23px;
      padding: 0;
    }
  }

  &--oldMd {
    height: 30px;
    padding: 0 9px;
    font-size: 11px;
    line-height: 12px;

    &.buttonElement--icon {
      width: 30px;
      padding: 0;
    }
  }

  &--md {
    @apply h-32 py-8 text-center text-11;
  }

  &--mdWider {
    height: 30px;
    padding: 0 35px;
  }

  &--ml {
    height: 35px;
    padding: 0 13px;
    font-size: 12px;

    &.buttonElement--icon {
      width: 35px;
      padding: 0;
    }
  }

  &--lg {
    @apply text-14;

    height: 40px;
    padding: 0 16px;

    &.buttonElement--icon {
      width: 40px;
      padding: 0;
    }
  }

  &--lgWider {
    height: 40px;
    padding: 0 45px;

    @media (max-width: 1440px) {
      padding: 0 25px;
    }
  }

  &--xl {
    height: 42px;
    padding: 0 24px;
    font-size: 14px;

    &.buttonElement--icon {
      width: 42px;
      padding: 0;
    }
  }

  &--xxl {
    height: 48px;

    @apply text-14 px-26;
  }

  &--block {
    width: 100%;
    white-space: nowrap;
  }

  &--disabled {
    opacity: .65;
    cursor: not-allowed;
    pointer-events: auto;

    &.buttonElement--default:hover {
      background-color: $lightgray;
    }
  }

  &--no-border {
    border: none;
  }

  &--animate {
    animation: rotation 1s infinite linear;
  }

  .fa ~ .buttonElement__text {
    margin-left: 5px;
  }

  .fa-lock {
    position: relative;
    top: 1px;
  }

  &--narrow {
    padding-left: 5px;
    padding-right: 5px;
    min-width: 61px;
  }

  &--inBorder {
    position: relative;
    top: -1px;
    right: -1px;
  }

  &--isResponsiveHeight {
    height: 100%;
  }
}

@keyframes rotation {
  0% {
    transform:rotate(0deg);
  }

  100% {
    transform:rotate(360deg);
  }
}
