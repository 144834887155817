$isProd: true;
.loaderInnerBall {
  margin: 0 auto;
  display: inline-block;

  & > div {
    @apply bg-primary;
  }

  &.loader-07 {
    transform: scale(0.7);
  }

  &.pageCenter {
    position: relative;
    top: calc(50vh - 130px);
    left: 50%;
    transition: 0.1s;
  }

  &.elementCenter {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    left: 50%;
    transition: 0.1s;
  }
}

