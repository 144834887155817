$isProd: true;
@import "../../../styles/colors";

.container {
  &:after {
    content: '';

    border-style: solid;
    border-width: 8px 8px 0 8px;

    @apply absolute left-0 right-0 -bottom-7 w-0 h-0 m-auto;
  }

  &:before {
    content: '';

    border-style: solid;
    border-width: 8px 8px 0 8px;
    border-color: $lightgray-2 transparent transparent transparent;

    @apply border-t-gray-300 border-r-transparent border-b-transparent border-l-transparent absolute left-0 right-0 -bottom-8 w-0 h-0 m-auto;
  }
}

.default {
  @apply bg-white;

  &:after {
    @apply border-t-white border-r-transparent border-b-transparent border-l-transparent;
  }
}

.primary {
  @apply bg-gray-50;

  &:after {
    @apply border-t-gray-50 border-r-transparent border-b-transparent border-l-transparent;
  }
}
