// MIND: there is src/constants/colorConstants.tsx file besides this one
$black: #000000;
$white: #fff;

// 50 shades of grey
$gray: #4a4f63;
$gray-border: #ddd;
$table-gray-border: #e7e7e7;
$gray-text: #666;
$gray-text-dark: #555;
$darkgray-text: #333333;
$lightgray-text: #999999;
$mediumgray: #eee;
$darkgray: #777777;
$lightgray: #fafafa;
$lightgray-2: #eaeaea;
$lightgray-3: #ccc;
$lightgraybtn: #f0f0f0;
$table_header: #f9f9f9;
$table_hover: lighten($gray-text, 55%);
$table_border: #ECECEC;

$lightsky: #f5fafd;
$darksky: #7da8d0;
$lightblue: #C5CBE2;

$primary: #366AD9;
$primary_hover: #264EAD;

$light_primary: #4d79d5;
$blue: #46a9ff;

$success: #6bbc56; /*green*/
$success_hover: #53a03f;

$warning: #e19b0b; /*yellow*/
$warning_hover: #c98a0a;

$alert: #8368c3; /*violet*/
$alert_hover: #6647b2;

$info: #569EBC; /*lightblue*/
$info_hover: #3f83a0;

$danger: #bc566b; /*red*/
$danger_hover: #a03f53;

$system: #56bca7; /*asure*/
$system_hover: #3fa08c;

$dark-green: #63AD50;
$light-green: #9EBC56; // 4 star reviews
$peach: #FF875F;       // 2 star reviews
$brown: #d3c4c4;
$dark-brown: #916C6C;
$move: #B168C3;        // like $alert. Located only on pricing page

$stripes: linear-gradient(135deg, rgba(0, 0, 0, 0.04) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.04) 50%, rgba(0, 0, 0, 0.04) 75%, transparent 75%, transparent);
