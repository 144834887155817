$isProd: true;
@tailwind components;

@layer components {
  .hidden-1621 {
    @apply hidden mw-1621:inline-block;
  }

  .hidden-ka-buttons {
    @apply w-2 opacity-0 overflow-hidden;
    @media (min-width: 1752px) {
      @apply w-auto opacity-1;
    }
  }

  .hidden-1499 {
    @apply hidden mw-1499:inline-block;
  }

  .visible-1499 {
    @apply inline-block mw-1499:hidden;
  }

  .hidden-1366 {
    @apply hidden mw-1366:inline-block;
  }

  .hidden-1281 {
    @apply hidden mw-1281:inline-block;
  }

  .hidden-mobile {
    @apply hidden desktop:inline-block;
  }

  .visible-mobile {
    @apply inline-block desktop:hidden;
  }

  .visible-1366 {
    @apply inline-block mw-1366:hidden;
  }
}
