$isProd: true;
@import "../../styles/colors";

.Toastify  {

  &__toast {
    font-size: 14px;
    padding: 1em;
    min-height: 0;

    @media (min-width: 768px) {
      width: 20vw;
    }

    &--success {
      background: $success;
    }

    &--error {
      background: $danger;
    }

    &--default {
      color: $gray-text;
      background-color: $lightgraybtn;
    }
  }

  &__close-button {
    color: $gray-text;
    text-shadow: 0 1px 0 #fff;
    opacity: .2;
    position: absolute;
    right: 10px;
    top: 5px;

    &:hover {
      opacity: .5;
    }
  }
}
