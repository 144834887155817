$isProd: true;
@import "../styles/colors";
@import "../styles/account-media-points";

body.account-body {
  background-color: $white;
  min-width: 0;

  @media (min-height: $tablet) {
    min-height: 768px;
  }

  @media (min-width: $laptop) {
    min-width: 1260px;
  }
}
