@import "./colors";

@mixin light-color-mixin($color, $percent, $hover-percent: $percent) {
  background-color: lighten($color, $percent) !important;

  .same-light-bg-color {
    color: lighten($color, $percent);
    background-color: lighten($color, $percent) !important;
  }

  &:hover {
    background-color: mix(lighten($color, $hover-percent), $table_hover, 50%) !important;

    .same-light-bg-color {
      color: mix(lighten($color, $hover-percent), $table_hover, 50%);
      background-color: mix(lighten($color, $hover-percent), $table_hover, 50%) !important;
    }
  }
}

@mixin light-color-text-mixin($color, $percent, $hover-percent: $percent) {
  color: lighten($color, $percent) !important;
}

@mixin light-mixin($percent: 35%, $hover-percent: $percent) {
  color: $gray-text;

  &.bg-info, &.btn-info { @include light-color-mixin(saturate($primary, 10%), $percent, $hover-percent); };
  &.bg-success, &.btn-success { @include light-color-mixin($success, $percent, $hover-percent); };
  &.bg-warning, &.btn-warning { @include light-color-mixin(lighten($warning, 5%), $percent, $hover-percent); };
  &.bg-danger, &.btn-danger { @include light-color-mixin(saturate($danger, 5%), $percent, $hover-percent); };
  &.bg-brown, &.btn-brown { @include light-color-mixin(darken($brown, 30%), $percent, $hover-percent); };
  &.bg-alert, &.btn-alert { @include light-color-mixin(saturate(darken($alert, 6%), 30%), $percent, $hover-percent); };
  &.bg-default { @include light-color-mixin($table_hover, 100%, 0%); };

  &.text-info { @include light-color-text-mixin(saturate($primary, 10%), $percent, $hover-percent); }
  &.text-success { @include light-color-text-mixin($success, 37%, $hover-percent); };
  &.text-warning { @include light-color-text-mixin(lighten($warning, 5%), $percent, $hover-percent); };
  &.text-danger { @include light-color-text-mixin(saturate($danger, 5%), 37%, $hover-percent); };
  &.text-brown { @include light-color-text-mixin(darken($brown, 30%), $percent, $hover-percent); };
  &.text-alert { @include light-color-text-mixin(saturate(darken($alert, 6%), 30%), $percent, $hover-percent); };
  &.text-default { @include light-color-text-mixin($table_hover, 100%, 0%); };
}

@mixin reset-list() {
  list-style-type: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

@mixin reset-button() {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  text-align: inherit;
  outline: none;
  cursor: pointer;
  background-color: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  box-shadow: none;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

@mixin transition($property...) {
  transition-property: $property;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

// deprecated function
@mixin bgImage($path, $pathX2: 0) {
  background-image: url("~images/#{$path}");

  @if ($pathX2 != 0) {
    @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (   min--moz-device-pixel-ratio: 2),
    only screen and (     -o-min-device-pixel-ratio: 2/1),
    only screen and (        min-device-pixel-ratio: 2),
    only screen and (                min-resolution: 192dpi),
    only screen and (                min-resolution: 2dppx) {
      background-image: url("~images/#{$pathX2}");
    }
  }
}

// deprecated function
@mixin get-bg-icon($path, $color) {
  background-color: $color;
  mask-image: url("~icons/#{$path}");
}


@mixin bgTwoImage($pathOne, $pathTwo, $levelDown: '') {
  @if ($isProd) {
    /* webpackIgnore: true */
    background-image: url("#{$levelDown}../img/keep-path/#{$pathOne}"), url("#{$levelDown}../img/keep-path/#{$pathTwo}");
  } @else {
    background-image: url("../images/#{$pathOne}"), url("../images/#{$pathTwo}");
  }
}