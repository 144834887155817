$isProd: true;
.driver-popover {
  @apply p-22 rounded-6 bg-rb-accent-blue-default;
  box-shadow: 0 4px 4px 0 rgba(57, 69, 97, 0.25);
}

.driver-popover-title {
  @apply flex items-center text-14 font-semibold text-rb-font-white tracking-2 leading-18 mb-8 font-base #{!important};
}

.driver-popover-description {
  @apply text-13 text-rb-font-white leading-18 m-0 font-base;
}

.driver-popover-footer {
  @apply mt-20;
}

.driver-popover-navigation-btns {
  @apply justify-between;
}

.driver-popover-prev-btn, .driver-popover-next-btn {
  @apply flex items-center px-8 py-4 rounded-16 border-rb-bg-white bg-transparent text-rb-font-white text-11 font-bold font-base #{!important};
  text-shadow: none !important;
}

.driver-popover-btn-disabled {
  @apply opacity-40 #{!important};

  .icon, img {
    @apply cursor-default;
  }
}

.driver-popover-arrow, .driver-popover-progress-text {
  @apply hidden #{!important};
}

:not(body):has(>.driver-active-element) {
  @apply overflow-visible #{!important};
}
