$isProd: true;
@import "../../../styles/colors";

// #########################
// ### helpers
// #########################

.no-transition { transition: none; }
.border-0 { border: none }
.border {
    border: 1px solid;
    border-color: inherit;
}
.d-flex { display: flex; }
.d-block { display: block; }
.align-items-center { align-items: center; }
.flex-columns { flex-direction: column }
.d-inline-block { display: inline-block; }
.align-items-center { align-items: center; }
.justify-content-between { justify-content: space-between; }
.justify-content-end { justify-content: flex-end; }
.f-grow { flex: 1 }
.w-100 { width: 100% }
.relative { position: relative; }
.bg-danger-light { background: lighten($danger, 45%) !important; }
.bg-success-light { background: lighten($success, 45%) !important; }
.el-disabled {
  pointer-events: none;
  opacity: .4;
}

.no-hover {
    &:hover {
        color: inherit !important;
        background-color: inherit !important;
        opacity: inherit !important;
  }
}

.clearfix:after {
    content: '';
    display: table;
    clear: both;
}

.flex-full-center {
  justify-content: center;
  align-items: center;
}

.full-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.opacity-hover {
    transition: .2s;
    opacity: .6;
    &:hover {
        opacity: 1;
    }
}
.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.row.small-pad {
    margin-right:0;
    margin-left:0;
    .alert {
        margin-left: 4px;
        margin-right: 4px;
    }
}
.row.small-pad > [class*='col-'] {
    padding-right:4px;
    padding-left:4px;
}

.overlap {
  left: 0; top: 0; bottom: 0; right: 0; z-index: 100;
  position: absolute; display: flex; align-items: center
}

// opacity
.op-0 { opacity: 0; }
.op-1 { opacity: .1; }
.op-2 { opacity: .2; }
.op-3 { opacity: .3; }
.op-4 { opacity: .4; }
.op-5 { opacity: .5; }
.op-6 { opacity: .6; }
.op-7 { opacity: .7; }
.op-8 { opacity: .8; }
.op-9 { opacity: .9; }
.op-10 { opacity: 1; }

.fz-0 {
    font-size: 0px;
}
.fz-1 {
    font-size: 1px;
}
.fz-2 {
    font-size: 2px;
}
.fz-3 {
    font-size: 3px;
}
.fz-4 {
    font-size: 4px;
}
.fz-5 {
    font-size: 5px;
}
.fz-6 {
    font-size: 6px;
}
.fz-7 {
    font-size: 7px;
}
.fz-8 {
    font-size: 8px;
}
.fz-9 {
    font-size: 9px;
}
.fz-10 {
    font-size: 10px;
}
.fz-11 {
    font-size: 11px;
}
.fz-12 {
    font-size: 12px;
}
.fz-13 {
    font-size: 13px;
}
.fz-14 {
    font-size: 14px;
}
.fz-15 {
    font-size: 15px;
}
.fz-16 {
    font-size: 16px;
}
.fz-17 {
    font-size: 17px;
}
.fz-18 {
    font-size: 18px;
}
.fz-19 {
    font-size: 19px;
}
.fz-20 {
    font-size: 20px;
}
.fz-21 {
    font-size: 21px;
}
.fz-22 {
    font-size: 22px;
}
.fz-23 {
    font-size: 23px;
}
.fz-24 {
    font-size: 24px;
}
.fz-25 {
    font-size: 25px;
}
.fz-26 {
    font-size: 26px;
}
.fw-100 {
    font-weight: 100;
}
.fw-200 {
    font-weight: 200;
}
.fw-300 {
    font-weight: 300;
}
.fw-400 {
    font-weight: 400;
}
.fw-500 {
    font-weight: 500;
}
.fw-600 {
    font-weight: 600;
}
.fw-700 {
    font-weight: 700;
}
.fw-800 {
    font-weight: 800;
}

// margin
.ml-auto { margin-left: auto; }
.mr-auto { margin-right: auto; }


.m-0 {
    margin: 0px;
}
.m-2 {
    margin: 2px;
}
.m-4 {
    margin: 4px;
}
.m-6 {
    margin: 6px;
}
.m-8 {
    margin: 8px;
}
.m-10 {
    margin: 10px;
}
.m-12 {
    margin: 12px;
}
.m-14 {
    margin: 14px;
}
.m-16 {
    margin: 16px;
}
.m-18 {
    margin: 18px;
}
.m-20 {
    margin: 20px;
}
.m-22 {
    margin: 22px;
}
.m-24 {
    margin: 24px;
}
.m-26 {
    margin: 26px;
}
.m-28 {
    margin: 28px;
}
.m-30 {
    margin: 30px;
}
.m-32 {
    margin: 32px;
}
.m-34 {
    margin: 34px;
}
.m-36 {
    margin: 36px;
}
.m-38 {
    margin: 38px;
}
.m-40 {
    margin: 40px;
}
.m-42 {
    margin: 42px;
}
.m-44 {
    margin: 44px;
}
.m-46 {
    margin: 46px;
}
.m-48 {
    margin: 48px;
}
.m-50 {
    margin: 50px;
}
.m-52 {
    margin: 52px;
}
.m-54 {
    margin: 54px;
}
.m-56 {
    margin: 56px;
}
.m-58 {
    margin: 58px;
}
.m-60 {
    margin: 60px;
}
.m-62 {
    margin: 62px;
}
.m-64 {
    margin: 64px;
}
.m-66 {
    margin: 66px;
}
.m-68 {
    margin: 68px;
}
.m-70 {
    margin: 70px;
}
.m-72 {
    margin: 72px;
}
.m-74 {
    margin: 74px;
}
.m-76 {
    margin: 76px;
}
.m-78 {
    margin: 78px;
}
.m-80 {
    margin: 80px;
}
.m-82 {
    margin: 82px;
}
.m-84 {
    margin: 84px;
}
.m-86 {
    margin: 86px;
}
.m-88 {
    margin: 88px;
}
.m-90 {
    margin: 90px;
}
.m-92 {
    margin: 92px;
}
.m-94 {
    margin: 94px;
}
.m-96 {
    margin: 96px;
}
.m-98 {
    margin: 98px;
}
.m-100 {
    margin: 100px;
}
.m-0 {
    margin: 0px;
}
.m-5 {
    margin: 5px;
}
.m-10 {
    margin: 10px;
}
.m-15 {
    margin: 15px;
}
.m-20 {
    margin: 20px;
}
.m-25 {
    margin: 25px;
}
.m-30 {
    margin: 30px;
}
.m-35 {
    margin: 35px;
}
.m-40 {
    margin: 40px;
}
.m-45 {
    margin: 45px;
}
.m-50 {
    margin: 50px;
}
.ml-0 {
    margin-left: 0px;
}
.ml-2 {
    margin-left: 2px;
}
.ml-4 {
    margin-left: 4px;
}
.ml-6 {
    margin-left: 6px;
}
.ml-8 {
    margin-left: 8px;
}
.ml-10 {
    margin-left: 10px;
}
.ml-12 {
    margin-left: 12px;
}
.ml-14 {
    margin-left: 14px;
}
.ml-16 {
    margin-left: 16px;
}
.ml-18 {
    margin-left: 18px;
}
.ml-20 {
    margin-left: 20px;
}
.ml-22 {
    margin-left: 22px;
}
.ml-24 {
    margin-left: 24px;
}
.ml-26 {
    margin-left: 26px;
}
.ml-28 {
    margin-left: 28px;
}
.ml-30 {
    margin-left: 30px;
}
.ml-32 {
    margin-left: 32px;
}
.ml-34 {
    margin-left: 34px;
}
.ml-36 {
    margin-left: 36px;
}
.ml-38 {
    margin-left: 38px;
}
.ml-40 {
    margin-left: 40px;
}
.ml-0 {
    margin-left: 0px;
}
.ml-5 {
    margin-left: 5px;
}
.ml-10 {
    margin-left: 10px;
}
.ml-15 {
    margin-left: 15px;
}
.ml-20 {
    margin-left: 20px;
}
.ml-25 {
    margin-left: 25px;
}
.ml-30 {
    margin-left: 30px;
}
.ml-35 {
    margin-left: 35px;
}
.ml-40 {
    margin-left: 40px;
}
.ml-45 {
    margin-left: 45px;
}
.ml-50 {
    margin-left: 50px;
}
.mr-0 {
    margin-right: 0px;
}
.mr-2 {
    margin-right: 2px;
}
.mr-4 {
    margin-right: 4px;
}
.mr-6 {
    margin-right: 6px;
}
.mr-8 {
    margin-right: 8px;
}
.mr-10 {
    margin-right: 10px;
}
.mr-12 {
    margin-right: 12px;
}
.mr-14 {
    margin-right: 14px;
}
.mr-16 {
    margin-right: 16px;
}
.mr-18 {
    margin-right: 18px;
}
.mr-20 {
    margin-right: 20px;
}
.mr-22 {
    margin-right: 22px;
}
.mr-24 {
    margin-right: 24px;
}
.mr-26 {
    margin-right: 26px;
}
.mr-28 {
    margin-right: 28px;
}
.mr-30 {
    margin-right: 30px;
}
.mr-32 {
    margin-right: 32px;
}
.mr-34 {
    margin-right: 34px;
}
.mr-36 {
    margin-right: 36px;
}
.mr-38 {
    margin-right: 38px;
}
.mr-40 {
    margin-right: 40px;
}
.mr-0 {
    margin-right: 0px;
}
.mr-5 {
    margin-right: 5px;
}
.mr-10 {
    margin-right: 10px;
}
.mr-15 {
    margin-right: 15px;
}
.mr-20 {
    margin-right: 20px;
}
.mr-25 {
    margin-right: 25px;
}
.mr-30 {
    margin-right: 30px;
}
.mr-35 {
    margin-right: 35px;
}
.mr-40 {
    margin-right: 40px;
}
.mr-45 {
    margin-right: 45px;
}
.mr-50 {
    margin-right: 50px;
}
.mt-0 {
    margin-top: 0px;
}
.mt-2 {
    margin-top: 2px;
}
.mt-4 {
    margin-top: 4px;
}
.mt-6 {
    margin-top: 6px;
}
.mt-8 {
    margin-top: 8px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-12 {
    margin-top: 12px;
}
.mt-14 {
    margin-top: 14px;
}
.mt-16 {
    margin-top: 16px;
}
.mt-18 {
    margin-top: 18px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-22 {
    margin-top: 22px;
}
.mt-24 {
    margin-top: 24px;
}
.mt-26 {
    margin-top: 26px;
}
.mt-28 {
    margin-top: 28px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-32 {
    margin-top: 32px;
}
.mt-34 {
    margin-top: 34px;
}
.mt-36 {
    margin-top: 36px;
}
.mt-38 {
    margin-top: 38px;
}
.mt-40 {
    margin-top: 40px;
}
.mt-0 {
    margin-top: 0px;
}
.mt-5 {
    margin-top: 5px;
}
.mt-10 {
    margin-top: 10px;
}
.mt-15 {
    margin-top: 15px;
}
.mt-20 {
    margin-top: 20px;
}
.mt-25 {
    margin-top: 25px;
}
.mt-30 {
    margin-top: 30px;
}
.mt-35 {
    margin-top: 35px;
}
.mt-40 {
    margin-top: 40px;
}
.mt-45 {
    margin-top: 45px;
}
.mt-50 {
    margin-top: 50px;
}
.mb-0 {
    margin-bottom: 0px;
}
.mb-2 {
    margin-bottom: 2px;
}
.mb-4 {
    margin-bottom: 4px;
}
.mb-6 {
    margin-bottom: 6px;
}
.mb-8 {
    margin-bottom: 8px;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb-12 {
    margin-bottom: 12px;
}
.mb-14 {
    margin-bottom: 14px;
}
.mb-16 {
    margin-bottom: 16px;
}
.mb-18 {
    margin-bottom: 18px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-22 {
    margin-bottom: 22px;
}
.mb-24 {
    margin-bottom: 24px;
}
.mb-26 {
    margin-bottom: 26px;
}
.mb-28 {
    margin-bottom: 28px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-32 {
    margin-bottom: 32px;
}
.mb-34 {
    margin-bottom: 34px;
}
.mb-36 {
    margin-bottom: 36px;
}
.mb-38 {
    margin-bottom: 38px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-0 {
    margin-bottom: 0px;
}
.mb-5 {
    margin-bottom: 5px;
}
.mb-10 {
    margin-bottom: 10px;
}
.mb-15 {
    margin-bottom: 15px;
}
.mb-20 {
    margin-bottom: 20px;
}
.mb-25 {
    margin-bottom: 25px;
}
.mb-30 {
    margin-bottom: 30px;
}
.mb-35 {
    margin-bottom: 35px;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-45 {
    margin-bottom: 45px;
}
.mb-50 {
    margin-bottom: 50px;
}
.ml-a {
    margin-left: auto;
}
.mr-a {
    margin-right: auto;
}
.p-0 {
    padding: 0px;
}
.p-2 {
    padding: 2px;
}
.p-4 {
    padding: 4px;
}
.p-6 {
    padding: 6px;
}
.p-8 {
    padding: 8px;
}
.p-10 {
    padding: 10px;
}
.p-12 {
    padding: 12px;
}
.p-14 {
    padding: 14px;
}
.p-16 {
    padding: 16px;
}
.p-18 {
    padding: 18px;
}
.p-20 {
    padding: 20px;
}
.p-22 {
    padding: 22px;
}
.p-24 {
    padding: 24px;
}
.p-26 {
    padding: 26px;
}
.p-28 {
    padding: 28px;
}
.p-30 {
    padding: 30px;
}
.p-32 {
    padding: 32px;
}
.p-34 {
    padding: 34px;
}
.p-36 {
    padding: 36px;
}
.p-38 {
    padding: 38px;
}
.p-40 {
    padding: 40px;
}
.p-0 {
    padding: 0px;
}
.p-5 {
    padding: 5px;
}
.p-10 {
    padding: 10px;
}
.p-15 {
    padding: 15px;
}
.p-20 {
    padding: 20px;
}
.p-25 {
    padding: 25px;
}
.p-30 {
    padding: 30px;
}
.p-35 {
    padding: 35px;
}
.p-40 {
    padding: 40px;
}
.p-45 {
    padding: 45px;
}
.p-50 {
    padding: 50px;
}
.pl-0 {
    padding-left: 0px;
}
.pl-2 {
    padding-left: 2px;
}
.pl-4 {
    padding-left: 4px;
}
.pl-6 {
    padding-left: 6px;
}
.pl-8 {
    padding-left: 8px;
}
.pl-10 {
    padding-left: 10px;
}
.pl-12 {
    padding-left: 12px;
}
.pl-14 {
    padding-left: 14px;
}
.pl-16 {
    padding-left: 16px;
}
.pl-18 {
    padding-left: 18px;
}
.pl-20 {
    padding-left: 20px;
}
.pl-22 {
    padding-left: 22px;
}
.pl-24 {
    padding-left: 24px;
}
.pl-26 {
    padding-left: 26px;
}
.pl-28 {
    padding-left: 28px;
}
.pl-30 {
    padding-left: 30px;
}
.pl-32 {
    padding-left: 32px;
}
.pl-34 {
    padding-left: 34px;
}
.pl-36 {
    padding-left: 36px;
}
.pl-38 {
    padding-left: 38px;
}
.pl-40 {
    padding-left: 40px;
}
.pl-0 {
    padding-left: 0px;
}
.pl-5 {
    padding-left: 5px;
}
.pl-10 {
    padding-left: 10px;
}
.pl-15 {
    padding-left: 15px;
}
.pl-20 {
    padding-left: 20px;
}
.pl-25 {
    padding-left: 25px;
}
.pl-30 {
    padding-left: 30px;
}
.pl-35 {
    padding-left: 35px;
}
.pl-40 {
    padding-left: 40px;
}
.pl-45 {
    padding-left: 45px;
}
.pl-50 {
    padding-left: 50px;
}
.pr-0 {
    padding-right: 0px;
}
.pr-2 {
    padding-right: 2px;
}
.pr-4 {
    padding-right: 4px;
}
.pr-6 {
    padding-right: 6px;
}
.pr-8 {
    padding-right: 8px;
}
.pr-10 {
    padding-right: 10px;
}
.pr-12 {
    padding-right: 12px;
}
.pr-14 {
    padding-right: 14px;
}
.pr-16 {
    padding-right: 16px;
}
.pr-18 {
    padding-right: 18px;
}
.pr-20 {
    padding-right: 20px;
}
.pr-22 {
    padding-right: 22px;
}
.pr-24 {
    padding-right: 24px;
}
.pr-26 {
    padding-right: 26px;
}
.pr-28 {
    padding-right: 28px;
}
.pr-30 {
    padding-right: 30px;
}
.pr-32 {
    padding-right: 32px;
}
.pr-34 {
    padding-right: 34px;
}
.pr-36 {
    padding-right: 36px;
}
.pr-38 {
    padding-right: 38px;
}
.pr-40 {
    padding-right: 40px;
}
.pr-0 {
    padding-right: 0px;
}
.pr-5 {
    padding-right: 5px;
}
.pr-10 {
    padding-right: 10px;
}
.pr-15 {
    padding-right: 15px;
}
.pr-20 {
    padding-right: 20px;
}
.pr-25 {
    padding-right: 25px;
}
.pr-30 {
    padding-right: 30px;
}
.pr-35 {
    padding-right: 35px;
}
.pr-40 {
    padding-right: 40px;
}
.pr-45 {
    padding-right: 45px;
}
.pr-50 {
    padding-right: 50px;
}
.pt-0 {
    padding-top: 0px;
}
.pt-2 {
    padding-top: 2px;
}
.pt-4 {
    padding-top: 4px;
}
.pt-6 {
    padding-top: 6px;
}
.pt-8 {
    padding-top: 8px;
}
.pt-10 {
    padding-top: 10px;
}
.pt-12 {
    padding-top: 12px;
}
.pt-14 {
    padding-top: 14px;
}
.pt-16 {
    padding-top: 16px;
}
.pt-18 {
    padding-top: 18px;
}
.pt-20 {
    padding-top: 20px;
}
.pt-22 {
    padding-top: 22px;
}
.pt-24 {
    padding-top: 24px;
}
.pt-26 {
    padding-top: 26px;
}
.pt-28 {
    padding-top: 28px;
}
.pt-30 {
    padding-top: 30px;
}
.pt-32 {
    padding-top: 32px;
}
.pt-34 {
    padding-top: 34px;
}
.pt-36 {
    padding-top: 36px;
}
.pt-38 {
    padding-top: 38px;
}
.pt-40 {
    padding-top: 40px;
}
.pt-0 {
    padding-top: 0px;
}
.pt-5 {
    padding-top: 5px;
}
.pt-10 {
    padding-top: 10px;
}
.pt-15 {
    padding-top: 15px;
}
.pt-20 {
    padding-top: 20px;
}
.pt-25 {
    padding-top: 25px;
}
.pt-30 {
    padding-top: 30px;
}
.pt-35 {
    padding-top: 35px;
}
.pt-40 {
    padding-top: 40px;
}
.pt-45 {
    padding-top: 45px;
}
.pt-50 {
    padding-top: 50px;
}
.pb-0 {
    padding-bottom: 0px;
}
.pb-2 {
    padding-bottom: 2px;
}
.pb-4 {
    padding-bottom: 4px;
}
.pb-6 {
    padding-bottom: 6px;
}
.pb-8 {
    padding-bottom: 8px;
}
.pb-10 {
    padding-bottom: 10px;
}
.pb-12 {
    padding-bottom: 12px;
}
.pb-14 {
    padding-bottom: 14px;
}
.pb-16 {
    padding-bottom: 16px;
}
.pb-18 {
    padding-bottom: 18px;
}
.pb-20 {
    padding-bottom: 20px;
}
.pb-22 {
    padding-bottom: 22px;
}
.pb-24 {
    padding-bottom: 24px;
}
.pb-26 {
    padding-bottom: 26px;
}
.pb-28 {
    padding-bottom: 28px;
}
.pb-30 {
    padding-bottom: 30px;
}
.pb-32 {
    padding-bottom: 32px;
}
.pb-34 {
    padding-bottom: 34px;
}
.pb-36 {
    padding-bottom: 36px;
}
.pb-38 {
    padding-bottom: 38px;
}
.pb-40 {
    padding-bottom: 40px;
}
.pb-0 {
    padding-bottom: 0px;
}
.pb-5 {
    padding-bottom: 5px;
}
.pb-10 {
    padding-bottom: 10px;
}
.pb-15 {
    padding-bottom: 15px;
}
.pb-20 {
    padding-bottom: 20px;
}
.pb-25 {
    padding-bottom: 25px;
}
.pb-30 {
    padding-bottom: 30px;
}
.pb-35 {
    padding-bottom: 35px;
}
.pb-40 {
    padding-bottom: 40px;
}
.pb-45 {
    padding-bottom: 45px;
}
.pb-50 {
    padding-bottom: 50px;
}

// text colors
.text-yellow { color: $warning; }
.text-darkgray { color: $darkgray-text; }

.nowrap {
    white-space: nowrap;
}
.noevent {
    pointer-events: none;
}

// #######################
// ### customs
// #######################
.input-group-btn.search-filter > .btn {
    line-height: 1;
    font-size: 14px;
    height: 35px;

    &.active {
        color: $primary;
    }
}

// #######################
// ### styles
// #######################
.input-group-btn.bordered > .btn.btn-default { border-color: rgba(0, 0, 0, 0.1); }
.submenu:hover {
    .dropdown-menu {
        display: block;
    }
}
.dropdown-submenu {
    position: relative;
}
.dropdown-submenu .dropdown-menu {
    top: -15px;
    left: 80%;
    margin-top: -1px;
}
.dropdown-menu.columns {
    width: 360px;
    & > li > a {
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 5px 8px !important;
    }
    & > li {
        float: left;
        width: 50%;
        vertical-align: top;
    }
}
.caret.caret-close {
    transform: rotate(-90deg);
}

// #######################
// ### directives
// #######################
.dynamic-up, .dynamic-down {
      position: relative;
}

.dynamic-up:after,
.dynamic-down:after {
    content: '';
    width: 0;
    height: 0;
    display: inline-block;
    border-style: solid;
    top: 0px;
    margin-left: 4px;
}
.dynamic-up:before,
.dynamic-down:before {
    content: attr(dynamic);
    font-size: .8em;
    font-weight: 600;
}

.dynamic-up:before,
.dynamic-up:after {
    color: $success;
}

.dynamic-up:after {
    border-color: transparent transparent $success transparent;
    border-width: 0 .4em .6em .4em;
}

.dynamic-down:before,
.dynamic-down:after {
    color: $danger;
}

.dynamic-down:after {
    border-width: .6em .4em 0 .4em;
    border-color: $danger transparent transparent transparent;
    transform: translateY(1px);
}
.dynamic-down:after {
    transform: translateY(0px);
}

// #######################
// ## angular-animations
// #######################

.ng-slide.ng-hide {
  opacity: 0;
  transform: translateY(-20px)
}

.ng-slide.ng-hide-add,
.ng-slide.ng-hide-remove {
  transition: all ease-out 0.3s;
}


.ng-fade.ng-hide {
  opacity: 0;
}

.ng-fade.ng-hide-add,
.ng-fade.ng-hide-remove {
  transition: all linear 0.3s;
}

.ui-helper-hidden-accessible div {
    display: none;
}
