$isProd: true;
@import "../../../styles/mixins";

.baseSelector {
  &__control {
    @apply border-gray-200 rounded-4 cursor-pointer #{!important};

    &--is-focused {
      @apply border-primary shadow-none #{!important};
    }
  }

  &--noBorder & {
    &__control {
      @apply border-0 #{!important};
    }
  }

  &--noData & {
    &__control {
      @apply cursor-default #{!important};
    }
  }

  &__placeholder {
    @apply text-primary ml-0 #{!important};

    + div {
      @apply ml-0;
    }
  }

  &__indicator {
    @apply flex items-center justify-center cursor-pointer;
  }

  &__menu {
    @apply rounded-4 mx-0 my-2 shadow-8 z-20 #{!important};
  }

  &__menu-list {
    @apply py-8 px-16 overflow-x-hidden;

    & > * {
      @apply px-16 cursor-pointer;

      &:hover {
        @apply bg-primary bg-opacity-5;
      }
    }
  }

  &__value-container {
    @apply flex-nowrap whitespace-nowrap #{!important};
  }

  &--xs & {
    &__menu-list {
      @apply p-8;
    }

    &__control {
      @apply text-12;

      min-height: 24px;
      max-height: 24px;
    }

    &__value-container {
      @apply p-0 ml-8 mr-0;
    }

    &__indicator {
      @apply my-0 ml-2 mr-4 text-16;

      height: 16px;
      width: 16px;
    }
  }

  &--md & {
    &__menu-list {
      @apply p-8;
    }

    &__control {
      @apply text-12;

      min-height: 32px;
      max-height: 32px;
    }

    &__value-container {
      @apply p-0 ml-8 mr-0;
    }

    &__indicator {
      @apply my-0 ml-2 mr-4 text-24;

      height: 24px;
      width: 24px;
    }
  }

  &--lg & {
    &__control {
      @apply text-14;

      min-height: 40px;
      max-height: 40px;
    }

    &__placeholder {
      @apply text-14;
    }

    &__value-container {
      @apply p-0 ml-16 mr-0;
    }

    &__indicator {
      @apply my-0 ml-2 mr-8 text-24;

      height: 24px;
      width: 24px;
    }
  }

  &--xl & {
    &__control {
      @apply text-14;

      min-height: 48px;
      max-height: 48px;
    }

    &__placeholder {
      @apply text-14;
    }

    &__value-container {
      @apply p-0 ml-16 mr-0;
    }

    &__indicator {
      @apply my-0 ml-2 mr-8 text-24;

      height: 24px;
      width: 24px;
    }
  }

  &--danger & {
    &__control {
      @apply border-danger #{!important};

      &--is-focused {
        @apply border-danger #{!important};
      }
    }
  }

  &--disabled {
    position: relative;
    @apply cursor-not-allowed opacity-65 #{!important};

    &:after {
      content: '';
      @apply absolute top-0 bottom-0 left-0 right-0;
    }
  }

  &--disabled & {
    &__control {
      @apply bg-white #{!important};
    }
  }

  &__multi-value {
    @apply bg-transparent #{!important};
  }

  &__value-container--is-multi {
    @apply text-primary;
  }

  &--optionBorder & {
    &__option {
      @apply border-t border-solid border-gray-300 first:border-t-0;
    }
  }
}
