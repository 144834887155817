@font-face {
    font-family: "icons";
    src: url("./icons.eot?9d37fdcc0c877cc26a9c3213220d0120#iefix") format("embedded-opentype"),
url("./icons.woff2?9d37fdcc0c877cc26a9c3213220d0120") format("woff2"),
url("./icons.woff?9d37fdcc0c877cc26a9c3213220d0120") format("woff");
}

span[class^="icon-"]:before, span[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-0:before {
    content: "\.";
}
.icon-1:before {
    content: "\/";
}
.icon-2:before {
    content: "\f";
}
.icon-3:before {
    content: "\r";
}
.icon-4:before {
    content: "\o";
}
.icon-5:before {
    content: "\n";
}
.icon-6:before {
    content: "\t";
}
.icon-7:before {
    content: "\e";
}
.icon-8:before {
    content: "\n";
}
.icon-9:before {
    content: "\d";
}
.icon-10:before {
    content: "\/";
}
.icon-11:before {
    content: "\s";
}
.icon-12:before {
    content: "\r";
}
.icon-13:before {
    content: "\c";
}
.icon-14:before {
    content: "\/";
}
.icon-15:before {
    content: "\f";
}
.icon-16:before {
    content: "\o";
}
.icon-17:before {
    content: "\n";
}
.icon-18:before {
    content: "\t";
}
.icon-19:before {
    content: "\s";
}
.icon-20:before {
    content: "\/";
}
.icon-21:before {
    content: "\a";
}
.icon-22:before {
    content: "\s";
}
.icon-23:before {
    content: "\o";
}
.icon-24:before {
    content: "\d";
}
.icon-25:before {
    content: "\e";
}
.icon-26:before {
    content: "\s";
}
.icon-27:before {
    content: "\k";
}
.icon-28:before {
    content: "\-";
}
.icon-29:before {
    content: "\f";
}
.icon-30:before {
    content: "\o";
}
.icon-31:before {
    content: "\n";
}
.icon-32:before {
    content: "\t";
}
.icon-33:before {
    content: "\/";
}
.icon-34:before {
    content: "\i";
}
.icon-35:before {
    content: "\c";
}
.icon-36:before {
    content: "\o";
}
.icon-37:before {
    content: "\n";
}
.icon-38:before {
    content: "\s";
}
.icon-39:before {
    content: "\.";
}
.icon-40:before {
    content: "\j";
}
.icon-41:before {
    content: "\s";
}
.icon-42:before {
    content: "\o";
}
.icon-43:before {
    content: "\n";
}
.icon-academy:before {
    content: "\f101";
}
.icon-activity-log:before {
    content: "\f102";
}
.icon-add:before {
    content: "\f103";
}
.icon-android-logo:before {
    content: "\f104";
}
.icon-annual-calendar:before {
    content: "\f105";
}
.icon-app-profile:before {
    content: "\f106";
}
.icon-apple-logo:before {
    content: "\f107";
}
.icon-apple-search-ads:before {
    content: "\f108";
}
.icon-arrow-bottom-line:before {
    content: "\f109";
}
.icon-arrow-bottom-solid:before {
    content: "\f10a";
}
.icon-arrow-bottom:before {
    content: "\f10b";
}
.icon-arrow-left:before {
    content: "\f10c";
}
.icon-arrow-right:before {
    content: "\f10d";
}
.icon-arrow-top:before {
    content: "\f10e";
}
.icon-aso-tools:before {
    content: "\f10f";
}
.icon-aso:before {
    content: "\f110";
}
.icon-automation:before {
    content: "\f111";
}
.icon-back:before {
    content: "\f112";
}
.icon-bar-chart-dynamics:before {
    content: "\f113";
}
.icon-bar-chart:before {
    content: "\f114";
}
.icon-bell-line-crossed:before {
    content: "\f115";
}
.icon-bell-line:before {
    content: "\f116";
}
.icon-bell:before {
    content: "\f117";
}
.icon-billing:before {
    content: "\f118";
}
.icon-boost:before {
    content: "\f119";
}
.icon-brand:before {
    content: "\f11a";
}
.icon-calendar-line:before {
    content: "\f11b";
}
.icon-calendar:before {
    content: "\f11c";
}
.icon-chart:before {
    content: "\f11d";
}
.icon-check:before {
    content: "\f11e";
}
.icon-chevron-bottom:before {
    content: "\f11f";
}
.icon-chevron-left:before {
    content: "\f120";
}
.icon-chevron-right:before {
    content: "\f121";
}
.icon-chevron-top:before {
    content: "\f122";
}
.icon-color:before {
    content: "\f123";
}
.icon-community:before {
    content: "\f124";
}
.icon-connections-hub:before {
    content: "\f125";
}
.icon-consulting:before {
    content: "\f126";
}
.icon-copy-solid:before {
    content: "\f127";
}
.icon-cource:before {
    content: "\f128";
}
.icon-credit-card:before {
    content: "\f129";
}
.icon-cross:before {
    content: "\f12a";
}
.icon-dollar-2:before {
    content: "\f12b";
}
.icon-dollar-api:before {
    content: "\f12c";
}
.icon-dollar:before {
    content: "\f12d";
}
.icon-download:before {
    content: "\f12e";
}
.icon-drag:before {
    content: "\f12f";
}
.icon-error-icon:before {
    content: "\f130";
}
.icon-eye:before {
    content: "\f131";
}
.icon-favorites-line:before {
    content: "\f132";
}
.icon-favorites:before {
    content: "\f133";
}
.icon-filter:before {
    content: "\f134";
}
.icon-folder:before {
    content: "\f135";
}
.icon-free-tools:before {
    content: "\f136";
}
.icon-gear:before {
    content: "\f137";
}
.icon-help:before {
    content: "\f138";
}
.icon-home:before {
    content: "\f139";
}
.icon-icon-app-store-localizations:before {
    content: "\f13a";
}
.icon-icon-apple-store:before {
    content: "\f13b";
}
.icon-icon-aso-comparative-report:before {
    content: "\f13c";
}
.icon-icon-auto-replies:before {
    content: "\f13d";
}
.icon-icon-auto-suggestions:before {
    content: "\f13e";
}
.icon-icon-auto-tags:before {
    content: "\f13f";
}
.icon-icon-burger:before {
    content: "\f140";
}
.icon-icon-categories-ranking:before {
    content: "\f141";
}
.icon-icon-charts:before {
    content: "\f142";
}
.icon-icon-competitors:before {
    content: "\f143";
}
.icon-icon-description-creator:before {
    content: "\f144";
}
.icon-icon-favorite-keywords:before {
    content: "\f145";
}
.icon-icon-featured-reviews:before {
    content: "\f146";
}
.icon-icon-find-track:before {
    content: "\f147";
}
.icon-icon-google-play:before {
    content: "\f148";
}
.icon-icon-guide:before {
    content: "\f149";
}
.icon-icon-live-positions:before {
    content: "\f14a";
}
.icon-icon-mail:before {
    content: "\f14b";
}
.icon-icon-more-3-dots:before {
    content: "\f14c";
}
.icon-icon-organic-report:before {
    content: "\f14d";
}
.icon-icon-phrase-mixer:before {
    content: "\f14e";
}
.icon-icon-rating-analysis:before {
    content: "\f14f";
}
.icon-icon-reviews-analysis:before {
    content: "\f150";
}
.icon-icon-selected-keywords:before {
    content: "\f151";
}
.icon-icon-tags-analysis:before {
    content: "\f152";
}
.icon-icon-tags:before {
    content: "\f153";
}
.icon-icon-target-blank:before {
    content: "\f154";
}
.icon-icon-templates:before {
    content: "\f155";
}
.icon-icon-text-metadata-builder:before {
    content: "\f156";
}
.icon-icon-traffic-sources:before {
    content: "\f157";
}
.icon-icon-zoom:before {
    content: "\f158";
}
.icon-info:before {
    content: "\f159";
}
.icon-instant:before {
    content: "\f15a";
}
.icon-integrations:before {
    content: "\f15b";
}
.icon-invite:before {
    content: "\f15c";
}
.icon-lamp:before {
    content: "\f15d";
}
.icon-line-chart:before {
    content: "\f15e";
}
.icon-lock-line:before {
    content: "\f15f";
}
.icon-lock-solid:before {
    content: "\f160";
}
.icon-log:before {
    content: "\f161";
}
.icon-mail:before {
    content: "\f162";
}
.icon-menu:before {
    content: "\f163";
}
.icon-minus:before {
    content: "\f164";
}
.icon-monitoring:before {
    content: "\f165";
}
.icon-more-dots-horizontal:before {
    content: "\f166";
}
.icon-more-dots-vertical:before {
    content: "\f167";
}
.icon-my-apps:before {
    content: "\f168";
}
.icon-next:before {
    content: "\f169";
}
.icon-note-solid:before {
    content: "\f16a";
}
.icon-notification:before {
    content: "\f16b";
}
.icon-off:before {
    content: "\f16c";
}
.icon-pencil-edit:before {
    content: "\f16d";
}
.icon-percent:before {
    content: "\f16e";
}
.icon-plus:before {
    content: "\f16f";
}
.icon-profile:before {
    content: "\f170";
}
.icon-question:before {
    content: "\f171";
}
.icon-rank:before {
    content: "\f172";
}
.icon-refresh:before {
    content: "\f173";
}
.icon-report:before {
    content: "\f174";
}
.icon-resources:before {
    content: "\f175";
}
.icon-reviews-2:before {
    content: "\f176";
}
.icon-reviews:before {
    content: "\f177";
}
.icon-rocket:before {
    content: "\f178";
}
.icon-rustore:before {
    content: "\f179";
}
.icon-sale:before {
    content: "\f17a";
}
.icon-settings-nav:before {
    content: "\f17b";
}
.icon-shield:before {
    content: "\f17c";
}
.icon-sign-out:before {
    content: "\f17d";
}
.icon-sort:before {
    content: "\f17e";
}
.icon-star-list:before {
    content: "\f17f";
}
.icon-store-analytics-2:before {
    content: "\f180";
}
.icon-store-analytics:before {
    content: "\f181";
}
.icon-store-console:before {
    content: "\f182";
}
.icon-team:before {
    content: "\f183";
}
.icon-teammates:before {
    content: "\f184";
}
.icon-timer-clock-crossed:before {
    content: "\f185";
}
.icon-timer-clock-solid:before {
    content: "\f186";
}
.icon-timer-clock:before {
    content: "\f187";
}
.icon-translate:before {
    content: "\f188";
}
.icon-trash-line:before {
    content: "\f189";
}
.icon-trash-solid:before {
    content: "\f18a";
}
.icon-user:before {
    content: "\f18b";
}
.icon-zoom-not-found:before {
    content: "\f18c";
}
.icon-zoom:before {
    content: "\f18d";
}
